import React from 'react'
import styled from 'styled-components'

import IMG_SITE from './images/Cronometro-Desktop_2024.png'
import IMG_MOBILE from './images/Cronometro-mobile_2024.png'
import { media } from 'resources'
import { useCountdown } from './hooks/useCountdown'

export const FooterBanner = () => {
  const countDownEndDate = new Date('2025-01-02T02:59:59.000Z')
  const dateTimeAfterOneDays = countDownEndDate.getTime()
  const [days, hours, minutes, seconds] = useCountdown(dateTimeAfterOneDays)

  const time = `${days}d ${('00' + hours).slice(-2)}h ${('00' + minutes).slice(-2)}m ${('00' + seconds).slice(-2)}s`

  return (
    <FixedWrapper>
      <Link href='https://checkout.professorferretto.com.br/personal/?plano=c065e0d4-d49b-42b6-a555-269626e08398&.&field_action=12_meses?utm_source=site-principal&utm_medium=contador-site&utm_campaign=promo-natal-24&utm_content=&conversion=promo-natal-24' alt='Ver promoção'>
        <BackgroundImage />
        <CountdownGrid>
          <TimeBox>
            {time}
          </TimeBox>
        </CountdownGrid>
      </Link>
    </FixedWrapper>
  )
}

const FixedWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  flex-direction: column;
  align-items: center;
`

const BackgroundImage = styled.div`
  background: url(${IMG_SITE});
  bottom: 0px;
  width:100%;
  height: 15%;
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
  position: fixed;
   ${media.lessThan('md')`
    background: url(${IMG_MOBILE}) center no-repeat;
    background-size: 100% 100%;
  `}
`

const CountdownGrid = styled.div`

`
const TimeBox = styled.div`
    position: relative;
    font-size: 2.3vw;
    border-radius: 15px;
    text-align: center;
    color: #fff;
    z-index: 10;
    left: 17.5vw;
    bottom: 2vh;
    font-weight: bold;
    ${media.lessThan('md')`
      left: 0;
      bottom: 5vh;
      font-size: 10vw;
    `}
`

const Link = styled.a`
    position: relative;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
 `
