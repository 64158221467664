import { generateMedia } from 'styled-media-query'

const media = generateMedia({
  xxsxsm: '300px',
  xxxsm: '325px',
  xxsm: '380px',
  xsm: '400px',
  sm: '580px',
  smd: '768px',
  md: '900px',
  lg: '1100px',
  lgMin: '1099px',
  xl: '1300px',
  xl2: '1400px',
  xxl: '1600px',
  xxl2: '1920px',
  xxl3: '2133px',
  xxl4: '2400px',
  xxxl: '2560px',
  xxxl2: '3000px',
  xg: '4096px',
  xxg: '5120px',
})

export { media }
